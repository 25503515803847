import request from '@/utils/requestV2'
const qs = require('qs')

// 获取媒介组包资源详情
export function getOrderFixPage (data) {
  return request({
    url: '/ooh-order/v1/productfix/getorderfixpage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 媒介资源全部售卖记录
export function getProductSkuRecords (data) {
  return request({
    url: '/ooh-order/v1/productfix/getproductskurecords',
    method: 'post',
    data: qs.stringify(data)
  })
}
