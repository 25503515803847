<template>
    <div>
        <Row class="table-title p-t-2">
            <i-col span="6">名称</i-col>
            <i-col span="6">所属产品</i-col>
            <i-col span="6">价格</i-col>
            <i-col span="6">操作</i-col>
        </Row>
        <Row v-for="(resource,index) in list" :key="index" class="p-t-2" :class="computedRowClassName(resource,index)">
            <i-col span="6">{{resource.productskuName}}</i-col>
            <i-col span="6">{{resource.productName}}</i-col>
            <i-col span="6">{{formatMoney(resource.usePrice)}}</i-col>
            <i-col span="6">
                <a v-if="isManage" class="m-l-5" @click="handleChangeSchedule(resource)">档期</a>
                <a v-if="isManage && resource.bufferEndDate>resource.endDate" class="m-l-5" @click="handleChangeBufferEndDate(resource)">施工缓冲期</a>
                <a v-if="!customskuId" class="m-l-5 delete" @click="handleDeleteShow(resource)">删除</a>
            </i-col>
        </Row>

        <div class="paging_style">
            <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator @on-change="handlePageNumberChange"></Page>
        </div>
         <Modal v-model="showChangeSchedule" width="400" title="资源变更档期" :footer-hide="true">
            <editOrderItemSchedule :params="params" :chooseOrderItemId="chooseOrderItemId" @on-update-order-item="changeSchduleSuccess"/>
        </Modal>

        <Modal v-model="showChangeBufferEndDate" width="400" title="变更施工缓冲期" :footer-hide="true">
            <editOrderItemBufferDate ref="editOrderItemBufferDate" @on-update-order-item="changeSchduleSuccess"/>
        </Modal>
    </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import editOrderItemSchedule from '../common/EditOrderItemSchedule'
import editOrderItemBufferDate from '../common/EditOrderItemBufferDate'
import { getOrderFixPage } from '@/api/order/productfix'
import { deleteOrderItem } from '@/api/order/orderitem'

export default {
  components: {
    editOrderItemSchedule, editOrderItemBufferDate
  },
  props: {
    params: {
      type: Object
    },
    customskuId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      isManage: this.$store.state.order.isManage,
      list: [],
      query: { pageNumber: 1, pageSize: 10 },
      total: 0,
      showChangeSchedule: false, // 档期变更
      chooseOrderItemId: 0,

      showChangeBufferEndDate: false, // 施工缓冲期
      changeBufferBean: {}
    }
  },
  methods: {
    computedRowClassName (row, index) {
      if (row.conflict) {
        return 'table-conflict-row'
      } else if (index % 2 === 0) {
        return 'table-row-1'
      } else {
        return 'table-row-2'
      }
    },
    initPageData () {
      this.query.orderId = this.params.orderId
      this.query.productId = this.params.productId
      this.query.startDate = this.params.startDate
      this.query.endDate = this.params.endDate
      this.query.customskuId = this.customskuId || undefined
      getOrderFixPage(this.query).then(res => {
        this.total = res.totalRow
        this.list = res.list
      })
    },
    handlePageNumberChange (page) {
      this.query.pageNumber = page
      this.initPageData()
    },
    handleDeleteShow (resource) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '点击确定后资源将会从您订单里删除，请谨慎操作',
        onOk: () => {
          this.handleDelete(resource)
        }
      })
    },
    handleDelete (resource) {
      const deleteParam = {
        orderId: this.params.orderId,
        orderitemIds: JSON.stringify([resource.orderitemId])
      }

      deleteOrderItem(deleteParam).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({ desc: '删除成功' })
          this.$emit('on-update-order', this.params.categoryId)
        }
      })
    },
    handleChangeSchedule (resource) {
      this.chooseOrderItemId = resource.orderitemId
      this.showChangeSchedule = true
    },
    changeSchduleSuccess () {
      this.showChangeSchedule = false
      this.showChangeBufferEndDate = false
      this.$emit('on-update-order', this.params.categoryId)
    },
    handleChangeBufferEndDate (resource) {
      this.changeBufferBean = {
        orderId: this.params.orderId,
        orderitemId: resource.orderitemId,
        endDate: resource.endDate,
        bufferEndDate: resource.bufferEndDate
      }
      this.$refs.editOrderItemBufferDate.initChangeSchedule(this.changeBufferBean)
      this.showChangeBufferEndDate = true
    },
    formatMoney (number) {
      return toMoney(number)
    }
  },
  mounted () {
    this.initPageData()
    this.$emit('on-update-map', [])
  },
  watch: {
    params: function () {
      this.initPageData()
    }
  }
}
</script>
