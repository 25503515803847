<template>
    <div>
        <p class="remark">PS：设置施工缓冲期，最小值不能低于当前发布时长</p>
        <DatePicker type="date" v-model="changeScheduleDate" :start-date="changeStartDate" placeholder="选择施工缓冲期日期"
          :options="optionsChangeSchedule" style="width: 100%" :clearable="false"></DatePicker>

        <Row class="text-right p-t-10">
          <i-col span="24">
            <Button type="primary" @click="submitChangeSchedule" :disabled="changeScheduleDate===''">提交变更</Button>
          </i-col>
        </Row>

    </div>
</template>

<script>
import { ParseDate } from '@/utils/dateFormat'
import { changeBufferEndDate } from '@/api/order/orderitem'

export default {
  data () {
    return {
      params: {},
      changeScheduleDate: '',
      optionsChangeSchedule: {}
    }
  },
  computed: {
    changeStartDate () {
      if (this.params.endDate) {
        const sdate = this.params.endDate.split('-')

        return new Date(sdate[0], sdate[1] - 1, sdate[2])
      } else {
        return new Date()
      }
    }
  },
  methods: {
    initChangeSchedule (params) {
      this.params = params
      this.changeScheduleDate = this.params.bufferEndDate

      this.optionsChangeSchedule = {
        disabledDate: date => {
          const startTime = new Date(this.params.endDate).valueOf() - 86400000
          const endTime = new Date(this.params.bufferEndDate).valueOf()
          return (date.valueOf() <= startTime) || (date.valueOf() >= endTime)
        }
      }
    },
    submitChangeSchedule () {
      const model = {
        bufferEndDate: ParseDate(this.changeScheduleDate),
        orderId: this.params.orderId,
        orderitemId: this.params.orderitemId
      }

      changeBufferEndDate(model).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({ desc: '变更施工缓冲期成功' })
          this.$emit('on-update-order-item')
        }
      })
    }
  }
}
</script>
