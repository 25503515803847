<template>
    <div>
        <p class="remark">PS：设置发布结束日期后，将自动创建新的日期分组</p>
        <DatePicker type="date" v-model="changeScheduleDate" :start-date="changeStartDate" placeholder="选择发布结束日期"  :options="optionsChangeSchedule" style="width: 100%"></DatePicker>

        <Row class="text-right p-t-10">
          <i-col span="24">
            <Button type="primary" @click="submitChangeSchedule" :disabled="changeScheduleDate===''">提交变更</Button>
          </i-col>
        </Row>

    </div>
</template>

<script>
import { ParseDate } from '@/utils/dateFormat'
import { changeSchedule } from '@/api/order/orderitem'

export default {
  props: {
    params: Object,
    chooseOrderItemId: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      changeScheduleDate: '',
      optionsChangeSchedule: {}
    }
  },
  mounted () {
    this.initChangeSchedule()
  },
  computed: {
    changeStartDate () {
      const sdate = this.params.startDate.split('-')

      return new Date(sdate[0], sdate[1] - 1, sdate[2])
    }
  },
  methods: {
    submitChangeSchedule () {
      const model = {
        endDate: ParseDate(this.changeScheduleDate),
        orderId: this.params.orderId,
        orderitemId: this.chooseOrderItemId
      }

      changeSchedule(model).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({ desc: '变更档期成功' })
          this.$emit('on-update-order-item')
        }
      })
    },
    initChangeSchedule () {
      this.optionsChangeSchedule = {
        disabledDate: date => {
          const startTime = new Date(this.params.startDate).valueOf()
          const endTime = new Date(this.params.endDate).valueOf()
          return date && ((date.valueOf() <= startTime) || (date.valueOf() >= endTime))
        }
      }
    }
  }
}
</script>
