import request from '@/utils/requestV2'
const qs = require('qs')

// 同时加入多个地铁单品（不返回资源清单详情）
export function adddtProductSkus (data) {
  return request({
    url: '/ooh-order/v1/orderitem/adddtproductskus',
    method: 'post',
    timeout: 200000,
    data: qs.stringify(data)
  })
}
// 采购点播屏
export function addElectric (data) {
  return request({
    url: '/ooh-order/v1/orderitem/addelectric',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取订单资源变更记录
export function orderResourceChanges (data) {
  return request({
    url: '/ooh-order/v1/orderitem/orderresourcechanges',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 售卖单元缩减档期
export function changeSchedule (data) {
  return request({
    url: '/ooh-order/v1/orderitem/changeschedule',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 采购推荐方案
export function addOrderPlan (data) {
  return request({
    url: '/ooh-order/v1/orderitem/addplan',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 删除已采购的媒介单元
export function deleteOrderItem (data) {
  return request({
    url: '/ooh-order/v1/orderitem/deleteorderitem',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 调整施工缓冲期
export function changeBufferEndDate (data) {
  return request({
    url: '/ooh-order/v1/orderitem/changebufferenddate',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 订单下根据产品获取单品明细
export function getProductSkuList (data) {
  return request({
    url: '/ooh-order/v1/orderitem/getproductskulist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 订单下档期下产品平移冲突检测,返回有冲突的资源编号集合
export function checkMoveProductSchedule (data) {
  return request({
    url: '/ooh-order/v1/orderitem/checkMoveProductSchedule',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 订单下档期下产品平移，对应的合同档期自动平移
export function moveProductSchedule (data) {
  return request({
    url: '/ooh-order/v1/orderitem/moveProductSchedule',
    method: 'post',
    data: qs.stringify(data)
  })
}
